// 
// _card.scss
// 

// html,body{
//   max-width: 100%;
//   overflow-x: hidden
// }
.card {
  margin-bottom: $grid-gutter-width;
  box-shadow: $box-shadow;
}

.card-drop {
  color: $body-color;
}

.card-title {
  font-size: 15px;
  margin: 0 0 7px 0;
  font-weight: $font-weight-semibold;
}

.card-title-desc {
  color: $card-title-desc;
  margin-bottom: 24px;  
}




.complaince-modal{
  // position: relative;
  width: 800px !important ;
  height: 170px;
  padding: 17px 20px 24px 20px;
  margin-top: 10%;
  cursor: pointer;
  
  @media only screen and (max-width: 395px) {
    width: 100% !important;
  }

  .btn-rounded{
    width: 200px !important;
    
  }
  
  }
  
  .test{
    background-color: #7cb342 !important;
  
  }


  .compliance{
    height: 5em !important;
  }


  .card-text {
    height: 30px;
    line-height: 26px;
  }


  .welcome{

    background-color: #7cb342;
  }

  .dashboardcard {
    @media screen and (min-width:"960px") {
      height: 210px !important;
    }
  }


  .landing{

    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    @media  screen and (min-width:"960px") {
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: center;
      height: 240px !important;
    }
  }


  .landingbtn{

    color:black !important;
    background-color :#FFF0CACC !important;
    border-radius: 15px !important;
    border-color: #FFF0CACC !important;
    width: 100px !important;
    
    @media (max-width: 475.98px) {
     

      font-size: 18px !important;

  }

  }


  .landingfont{
    
font-size: 1.2rem;
margin-left: 50px;
    
    @media (max-width: 475.98px) {
     

      font-size: 16px !important;

  }
  }

.landingtext{
  margin-top: -50px;
}
  
.landingspace{

  @media (max-width: 500px) {
     

    margin-top: 60px;

}
}

  .landingimg{
    width: 100%;
    height: 500px;
    margin-top: -70px;
    padding-right: 90px;
    margin-bottom: 28px;
    height: 450px;
   
    @media (max-width: 475.98px) {
     

      margin-bottom: 20px !important;
      width: 100%;
      margin-left: 20%;


  }

  }



  .aboutspace{
    margin-top: 40px;
  }

  .about-text-space{
    margin-top: 55px;

    @media (max-width: 475.98px) {
     

      margin-left: -20px;


  }


  }
  .aboutimg{
    width: 100%;

    @media (max-width: 475.98px) {
     

      width: 100%;
      margin-left: 12px;


  }

  }


.agent-text{
  font-size: 40px;
  margin-left: 40%;
  @media (max-width: 475.98px) {
     

    font-size: 15px;
    
  margin-left: 25%;
  margin-bottom: 40px;


}
}

.agentimg{
  width: 100%;
  height: 500px;
  // padding-right: 90px;
  margin-bottom: 28px;
   height: 450px;
  @media (max-width: 475.98px) {
     

    width: auto;
    max-width: 100%;
    font-size: 20px;
    margin-left: 20px;
}
}

.getstarteddiv{
  height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
}

.getStartedCard{
  @media (max-width: 475.98px) {

    margin-left: 15px  !important;
    margin-right: -30px !important;
    }
}

.getstartedicon{
width: 30px
}


.getstarted-title{
  font-size: 20px;
}

.getstarted-text{
  font-size: 15px;
}
  .navbar-padding{
    @media (max-width: 475.98px) {

    margin-top: 15px  !important;
    margin-bottom: 10px !important;
    }
  }

  .info-tooltip {
    width: 260px !important;
    bottom: -8em;
    padding: 26px 11px;
    font-size: 0.76rem;
    color: #3a3a3a;
    background: #f9f3d9;
    text-align: center;
    position: absolute;
    z-index: 4;
    margin-top: -0.5rem;
    margin-left: -220px;
    // border: none;
  }

  .refferal-tooltip {
    
    width: 100px !important;
    background: #fbee9b;
      // top: 3rem;
      // bottom: 0;
      display: flex !important;
      justify-content: center;
      z-index: 4;

      align-items: center;
      font-weight: bold;
      margin-left: 60%;
      margin-right: 10%;
      // &::after {
      //   border-bottom: solid 5px rgb(221, 223, 102);
      // }
    
  }

  .transferbtn{


    margin-right: 30px;
    @media (max-width: 475.98px) {

      margin-right: 1px  !important;
      }
    
  }

  .passwordicon{
    margin-top: -30px;
    float: right;
    height: 15;
  }


  //Border Styling begin
.border-styling {
  border: solid #f7bd00 4px;
  box-shadow: none !important;
  border-radius: 10px !important;
}

@media only screen and (max-width: 500px) {
  .border-styling {
    border: solid #f7bd00 2px;
  }
}

//Border Styling end


//Account Style Start

@media only screen and (max-width: 515px) {
  .account_card.card {
    height: 200px !important;
  }

  p.bank-name {
    font-size: 1.5rem !important;
    margin: 0 !important;
    margin-top: 3rem !important;
  }

  p.bank-details {
    margin: 0 !important;
    margin-top: 12px !important;
  }
}
//Account Style End

.card-text {
  height: 30px;
  line-height: 26px;
}

.login{
height: 1000px;
min-height: 100%;

  background-image: url("../../../images/users/Login.png");
  
  background-size :  cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
   margin-top: 3rem !important;



}

.row {
  max-width: 100%;
  width: 100% !important;
}

body {
  overflow: hidden;
  overflow-y: auto;
}

.frontpage{

width: 100%;
  height: 600px;

  background-image: url("../../../images/users/earnwithherconomy.png");
  
  background-size :  contain;
  background-repeat: no-repeat;
  // background-position: center center;
  background-attachment: fixed;



  @media only screen and (max-width: 500px) {
    height: 800px;
    background-size :  cover;
    background-repeat: no-repeat;

  }

}




.btn-login{

  background: #292D32;
  color: #F4BB01 !important;

  
}


.input-login{
  background: #FFF9E5 !important;
}

.p-login{

  color: #F4BB01 !important;

  
}


.refferal{
  justify-content: space-between;
  align-items: center;

}

.button-gen{
  padding: 10px,5px !important;
  background-color: #F4BB01 !important;
  border-radius: 10px !important;
  border-color: #F4BB01;

}

.accountsetting p{
  @media only screen and (max-width: 515px) {
    font-size: 16px !important;
  }


}

.accountsetting img{
  @media only screen and (max-width: 515px) {
    height:1.5rem !important;
  }


}
.accountsetting p:hover{
  color: #F4BB01 !important;

}
.accountsetting i:hover{
  color: #F4BB01 !important;

}
.accountsetting img:hover{
  color: #F4BB01 !important;

}

.accountcard{
margin-bottom: 0px !important;
}

.accountcardbody{
  margin-bottom: -10px !important;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  }

  .transfercard {
    @media screen and (min-width:"960px") {
      height: 610px !important;
    }
  }

  .usergrowthcard {
    @media screen and (min-width:"960px") {
      height: 450px !important;
    }
  }

  .transfercard1 {
    @media screen and (min-width:"960px") {
      height: 400px !important;
    }
  }



  .hercolor{
    background-color: #F4BB01;
  }
  .hertextcolor{
    color:#F4BB01;
  }

  .lastest{
    @media screen and (min-width:"960px") {
      height: 415px !important;
    }
  }

  .transferbar{
    @media screen and (max-width:"515px") {

    height: 500px ;
    width : 100%;
    }
  }

  
  .sublist{

    display: flex;
    flex-direction: row;
    @media screen and (max-width:"414px") {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  }


  .float-ac{
    min-height: 100%;
  
    background-image: url("../../../images/users/Savings_background.png");
    border-radius: 10px;
    background-color:#f7bd00 ;
    box-shadow: none;
  
  
  
  }

  .faq-row-wrapper {
    .faq-title {
      margin-top: 90px;
      padding-left: 20px;
    }

    .faq-body {
        .faq-row {
            .row-title {
              margin-left: 20px;
              padding-left: 20px;
            }

            .row-content {
                .row-content-text {
                }
            }
        }
    }
}


.commission-button {
  height: 58px;
  display: flex;
  align-items: center;
  border: 2px solid #fabf01;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 25px;
  }

  .h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 500px) {
      font-size: 12px;
    }
  }
}

.row.message-cont {
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    width: 100%;
    overflow-wrap: anywhere;
    text-align: center;
  }
}

.modal-margin {
  margin-top: 10%

}

.mod-img {
  width: 4rem;
}

@media only screen and (max-width: 500px) {
  .modal-margin {
    margin-top: 40%;
  }

  .mobile-modal {
    margin-top: 300px;
    height: 400px;
  }
  .head-para {
    font-size: xx-small;
    margin-bottom: 1em;
  }
  .medium-para {
    font-size: 1.5rem !important;
    margin-bottom: 1em;
    text-align: center;
  }

  .para {
    font-size: small;
    margin-bottom: 1em;
    // text-align: center;
  }
.para-center {
  font-size: small;
  margin-bottom: 1em;
  text-align: center;
}

.para-head {
  font-size: medium;
  margin-bottom: 1em;
}
.para-head-center {
  font-size: medium;
  margin-bottom: 1em;
  text-align: center;
}

.para-small {
  font-size: smaller;
}
.para-small-center {
  font-size: smaller;
  text-align: center;
}

.para {
  font-size: small;
  margin-bottom: 0em;
}

.para-head {
  font-size: medium;
  margin-bottom: 0em;
}

.para-small {
  font-size: smaller;
}

.para-spacing {
  margin-left: 200px;
  margin-right: 200px;
}

.mod-img {
  width: 30px;
  height: 30px;
}
.pcenter {
  align-items: center;
}
}

.vault-button {
  height: 58px;
  display: flex;
  align-items: center;
  border: 2px solid #fabf01;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 25px;
  }

  .h3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 500px) {
      font-size: 12px;
    }
  }
}

.overall-card {
  min-height: 465px;
  border: 4px solid #fabf01;
  border-radius: 10px;
  padding: 31px 61px 36px 57px;
  box-shadow: none;

  @media only screen and (max-width: 800px) {
    padding: 31px 24px 41px 21px;
  }
}


.welcomepic{
  position:relative;
  margin-bottom: -125px;
  z-index: 4;
  width: 100px;
  border-radius: 50%;


  @media only screen and (max-width: 500px) {
    position:relative;
    margin-bottom: -230px;
    z-index: 4;
    width: 100px;
    border-radius: 50%;
    }
}

.welcomeIcon{

@media only screen and (max-width: 500px) {
  width: 150px;
  height: 300px;
  margin-top: 20px;
  // float: left;
  }

}
.welcomediv{

  float: right;
  @media only screen and (max-width: 500px) {
    // margin-top: 20px;

    }


}

.welcometext{
  @media only screen and (max-width: 500px) {
    // margin-top: 20px;
    width: 100%;

    }
}

.welcome{
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: center;


  @media only screen and (max-width: 500px) {
    display:block;
    direction: column;
    

    }
}

.top-card{
  @media only screen and (max-width: 500px) {
    height: 250px;
    

    }
}

.t-border{
  @media only screen and (max-width: 500px) {
    height: 100px;
    

    }
}

.report-width{
  @media only screen and (max-width: 500px) {
    width: 350px;
    

    }

}

.link-wrapper{
  margin-left: 10px;
}


.search-box{
  @media only screen and (max-width: 500px) {
    width:100%
  }
}
.searchdiv{
  display:flex;
  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }

}
.referral-link{
  width: 30%;
  border-radius: 20px;
  padding-top: 5px;
  margin-bottom: 25px;
  background-color: #F3F3F8;
  margin-left: 30%;

  @media only screen and (max-width: 500px) {
    width: 100%;
    border-radius: 20px;
    padding-top: 5px;
    margin-bottom: 25px;
    margin-left: 5%;
    padding-bottom: 5px;
    display: flex;


    }

}

.referral-content{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
align-items: center;
margin-left: 5%;
margin-right:5% ;
@media only screen and (max-width: 500px) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
align-items: center;
margin-left: 4%;
margin-right:4% ;
  

  }
}

.total{
   margin-left: 15px;

  @media only screen and (max-width: 500px) {
    margin-left: 30px;
    

    }

}

.payout{
  // margin-left: 15px;

  @media only screen and (max-width: 500px) {
    margin-left: 30px;
    

    }

}




.bottom-welcome{
  border-top: 1px solid #eff2f7 !important;
  height: 55px;
}

.about-title{
  margin-bottom: 10px !important;
  margin-left: 40%;
  font-size: 2.5rem;
}

.about-btn{
  margin-left: 200px;
}

.becomeagent{
  width: 100%;
  justify-content: center;
  align-items: center;
}

.reversemobile{
  

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column-reverse;   

    }
}

.linkbtn{
  background: rgb(55, 56, 57);
  border-radius: "20px";
  padding: "5px 10px 5px 10px";
  margin-bottom: "5px";
 display: flex;


  @media only screen and (max-width: 500px) {
    background: rgb(55, 56, 57);
    display: flex;
    direction: row;
    border-radius: "20px";
    padding: "5px 10px 5px 10px";
    margin-bottom: "5px";  

    }
}                   


.save{
  margin-right: 40px;
  margin-top: 10px;
  background-color: #fabf01;
  // padding: 10px 20px;
  border-radius: 30px;
  align-items: center;
  display: flex;
  font-size: 10px;
  font-weight: 400;
  border: none;
  height: 30px;


  @media only screen and (max-width: 500px) {
    margin-right: 10px;
    background-color: #fabf01;
    // padding: 5px 10px;
    border-radius: 30px;
    align-items: center;
    display: flex;
    font-size: 10px;
    font-weight: 400;  
    border: none;


    }

}

.commision__text{
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: -30px;
  border-radius: 5px;
  background-color: rgba(244, 244, 169, 0.5);


  @media  screen and (max-width:550px) {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
}